export default {
  forms: {
    add_driving_licence: 'Rijbewijs toevoegen',
    add_identity_card: 'Identiteitskaart toevoegen',
    contractually_frozen_warning:
      'Deze klant is gelinkt aan (minstens) één contract. Zorg ervoor dat je de nodige wijzigingen correct ingeven.',
    select_options: {
      available_locales: [
        {
          label: 'Frans',
          value: 'fr'
        },
        {
          label: 'Nederlands',
          value: 'nl'
        }
      ],
      civil_statuses: [
        {
          label: 'Ongehuwd',
          value: 'single'
        },
        {
          label: 'Weduwnaar',
          value: 'widower'
        },
        {
          label: 'Gescheiden',
          value: 'divorced'
        },
        {
          label: 'Gehuwd',
          value: 'married'
        },
        {
          label: 'Samenwonend',
          value: 'cohabitant'
        }
      ],
      genders: [
        {
          label: 'Man',
          value: 'male'
        },
        {
          label: 'Vrouw',
          value: 'female'
        }
      ],
      owner_titles: [
        {
          label: 'Bestuurder',
          value: 'administrator'
        },
        {
          label: 'Zelfstandige',
          value: 'independent'
        },
        {
          label: 'Beheerder',
          value: 'manager'
        }
      ],
      unfit_reasons: [
        {
          label: 'Gemeld',
          value: 'on_file'
        },
        {
          label: 'Gemeld voor schade',
          value: 'claim'
        },
        {
          label: 'Gemeld voor wanbetaling',
          value: 'unpaid'
        },
        {
          label: 'Agressief gedrag',
          value: 'aggressive'
        },
        {
          label: 'Tijdrovend',
          value: 'time_consuming'
        }
      ],
      professional_statuses: [
        {
          label: 'Werknemer',
          value: 'employee'
        },
        {
          label: 'Zelfstandige als natuurlijkpersoon',
          value: 'independent'
        },
        {
          label: 'Zelfstandige als rechtspersoon',
          value: 'independent_company'
        },
        {
          label: 'Ambtenaar',
          value: 'state_employee'
        },
        {
          label: 'Arbeider',
          value: 'workman'
        },
        {
          label: 'Gepensioneerd',
          value: 'retired'
        },
        {
          label: 'Werkloos',
          value: 'unemployed'
        },
        {
          label: 'Student',
          value: 'student'
        },
        {
          label: 'Andere',
          value: 'other'
        }
      ]
    },
    unfit:
      "Meer informatie over de weigering <a href='https://www.notion.so/seraphin-be/Acceptation-et-refus-client-c0fc8eeb1b9e4f729596a72bb69f7610?pvs=4' target='_blank'>hier</a>",
    user_locale_form_guidance:
      'Geef hier de taal aan waarin Yago communiceert met deze klant.<br>Dit is ook de taal waarin automatische e-mails/sms worden verzonden.',
    user_locale_form_title: 'Taal van de klant',
    user_team_form_guidance: 'Selecteer het team waarnaar je de klant wilt verplaatsen.',
    user_team_form_title: 'Klantteam wijzigen',
    user_team_form_placeholder: 'Selecteer een team',
    user_no_team: 'Deze klant heeft geen team',
    guidance_name_format:
      'Zorg ervoor dat je geen speciale tekens (zoals haakjes) gebruikt wanneer je de naam van de klant wijzigt!',
    detailed_unfit_disclaimer: 'Deze klant is geweigerd sinds {unfittedAt} door {unfitOperatorEmail}',
    unfit_disclaimer: 'Deze klant is geweigerd',
    edit_client_champion: 'Kies een andere Client Champion',
    edit_client_champion_guidance: 'Kies een Client Champion van het team: {team} om aan de klant toe te wijzen',
    no_client_champion: '- Geen toegewezen adviseur -',
    add_team_first_guidance: 'Wijs deze klant eerst toe aan een team',
    no_longer_client_champion:
      'De operator {operator} die toegewezen is aan deze klant is geen Client Champion meer of maakt geen deel uit meer uit van het team. Kies een ander teamlid'
  },
  header: {
    dropdown: {
      chargebee: 'Chargebee',
      pipedrive: 'Pipedrive',
      sendinblue: 'Sendinblue'
    },
    operator_comment: 'Opmerking voor de operator (privé)',
    tags: {
      gross_premium: 'TOTAAL: € {grossPremium}',
      unfit: 'Geweigerd',
      waiting_for_signature: 'Documenten wachten op ondertekening'
    },
    tooltips: {
      waiting_for_signature:
        'Documenten kunnen contracten, wijzigingen, SEPA-mandaten, opzeggingen of overdrachten zijn'
    },
    total_gross_premium: 'Totale premie:',
    user_locale: {
      fr: 'Franstalig',
      nl: 'Nederlandstalig'
    },
    refused_by: 'Klant geweigerd op {date} door {operator}',
    unfit_reasons: {
      on_file: 'Gemeld',
      claim: 'Gemeld voor schade',
      unpaid: 'Gemeld voor onbetaald',
      aggressive: 'Agressief gedrag',
      time_consuming: 'Tijdrovend'
    },
    pass: 'Assist',
    claimsRequest: 'Verkrijgen van schadeattest of kopie van contract voor klant'
  },
  journey: {
    aircall_modal: {
      comments: 'Eventuele opmerkingen:',
      lign_number: 'Lijnnaam:',
      not_found: 'Niet gevonden',
      operator: 'Call door operator:',
      recording: 'Opname van het Aircall-gesprek',
      team: 'Team:'
    },
    email_modal: {
      not_found: 'Niet gevonden'
    },
    new_message: '💬  Nieuwe SMS'
  },
  pending_actions: {
    amendments: 'Wijziging | Wijziging | Wijzigingen',
    confirmation: 'Wil je echt een herinneringsmail sturen naar {fullName}?',
    contracts: 'Contract | Contract | Contracten',
    documents_pending_upload: '- | Document wacht op upload | Documenten wachten op upload',
    documents_waiting_for_signature: '- | Document wacht op ondertekening| Documenten wachten op ondertekening',
    last_reminded_at: 'Laatste herinnering verzonden op ',
    last_reminder: 'De laatste herinnering was {date}.',
    licence_plates: '- | Ontbrekende kenteken | Ontbrekende kenteken',
    life_procedures: 'Levensprocedure | Levensprocedure | Levensprocedures',
    never_sent: 'Geen herinnering verzonden',
    pdf_procedures: 'PDF-procedure | PDF-procedure | PDF-procedures',
    sepa_mandates: 'SEPA-mandaat | SEPA-mandaat | SEPA-mandaten',
    terminations: 'Opzegging | Opzegging | Opzeggingen',
    title: 'Taken die de klant moet uitvoeren ⚠️',
    transfers: 'Overdracht | Overdracht | Overdrachten'
  },
  profile: {
    card_titles: {
      belongings: 'Risico-objecten',
      company_info: 'Bedrijfsgegevens',
      financial_data: 'Financiële gegevens',
      general_info: 'Algemene informatie'
    },
    elements: {
      address: 'Adres:',
      age: 'Leeftijd:',
      back: 'Achterzijde',
      bic: 'BIC:',
      chargebee: 'Link naar Chargebee:',
      date_of_birth: 'Geboortedatum:',
      email: 'E-mail:',
      front: 'Voorzijde',
      gender: 'Geslacht:',
      gross_premium: 'Totale premies:',
      iban: 'IBAN:',
      legal_name: 'Wettelijke naam:',
      mobile_phone: 'Mobiel nummer:',
      name: 'Naam:',
      owner_title: 'Functie van de klant:',
      subscription: 'Inschrijving',
      vat_number: 'BTW-nummer:'
    }
  },
  tabs: {
    accident_record: 'Schadehistoriek',
    contracts: 'Contracten',
    full_package_bundle: 'Aanbod',
    pdf_procedures: 'PDF-procedures',
    pending_actions: 'Te verrichten taken',
    profile: 'Profiel',
    wins: 'Wins'
  },
  client_from: 'Klant sinds ',
  terminated_from: 'Opgezegd sinds ',
  female_client_from: 'Klant sinds ',
  female_terminated_from: 'Opgezegd sinds ',
  from_in_years: '{years} jaar en {months} maand(en)',
  from_in_months: '{months} maand(en) en {days} dag(en)',
  from_in_days: '{days} dag(en)',
  missing_datas: '⚠️ Er ontbreken nog andere gegevens voor deze klant, vul deze aan'
}
