import initialState from '../_helpers/initialState'
import api from '@/services/api_v2'

export default {
  namespaced: true,
  state: {
    ...initialState,
    company: null,
    members: null,
    primaryContactMember: null,
    primaryContactMemberLoading: false,
    membersLoading: false
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_MEMBERS_LOADING(state, payload) {
      state.membersLoading = payload
    },
    SET_PRIMARY_CONTACT_MEMBER_LOADING(state, payload) {
      state.primaryContactMemberLoading = payload
    },
    SET_COMPANY(state, payload) {
      state.company = payload
    },
    SET_MEMBERS(state, payload) {
      state.members = payload
    },
    SET_PRIMARY_CONTACT_MEMBER(state, payload) {
      state.primaryContactMember = payload
    },
    SET_COMPANY_EMAIL(state, data) {
      state.company.email = data
    },
    commitClientChampion(state, operator) {
      state.company.key_account_manager = operator
    }
  },
  actions: {
    async editClientChampion({ commit }, data) {
      const response = await api.changeCompanyClientChampion(data)
      return response
    },
    async fetchCompany({ commit }, vatNumber) {
      commit('SET_LOADING', true)
      const response = await api.getCompany(vatNumber)
      if (response.data) commit('SET_COMPANY', response.data)
      commit('SET_LOADING', false)
    },
    async fetchMembers({ commit }, vatNumber) {
      commit('SET_MEMBERS_LOADING', true)
      const response = await api.getCompanyMembers(vatNumber)
      if (response.data) commit('SET_MEMBERS', response.data)
      commit('SET_MEMBERS_LOADING', false)
    },
    async fetchPrimaryContactMember({ commit }, vatNumber) {
      commit('SET_PRIMARY_CONTACT_MEMBER_LOADING', true)
      const response = await api.getCompanyPrimaryContactMember(vatNumber)
      commit('SET_PRIMARY_CONTACT_MEMBER', response.data.primary_contact_member)
      commit('SET_PRIMARY_CONTACT_MEMBER_LOADING', false)
    },
    async postClient({ commit }, payload) {
      try {
        const response = await api.registerClient(payload)
        return { uuid: response.data.data.user_id, message: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postMember({ commit }, payload) {
      try {
        const response = await api.addMember(payload)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postMemberRoles({ commit }, data) {
      try {
        const response = await api.addMemberRoles(data)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postMemberTitle({ commit }, data) {
      try {
        const response = await api.addMemberTitle(data)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postMemberPositions({ commit }, data) {
      try {
        const response = await api.addMemberPositions(data)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postCompanyEmail({ commit }, data) {
      try {
        const response = await api.changeCompanyEmail(data)
        commit('SET_COMPANY_EMAIL', data.payload.email)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    }
  }
}
