import { ONGOING_PURCHASE, WAITING_FOR_SIGNATURE, PROCESSED, FINISHED, ARCHIVED } from '../contract/constants'
import { SET_LOADING, SET_USER } from './constants'
import { user } from './services'
import belongings from './belongings'
import documents from './documents'
import journey from './journey'
import dayjs from 'dayjs'
import initialState from './initialState'
import api from '@/services/api_v2'

const { VUE_APP_SENDINBLUE_BASE_URL, VUE_APP_PIPEDRIVE_URL } = import.meta.env

export default {
  namespaced: true,
  modules: {
    belongings,
    documents,
    journey
  },
  state: {
    ...initialState
  },
  getters: {
    contractRequests(state) {
      return state.contractRequests
    },
    ageInMonths(state) {
      if (state.birthdate) return dayjs().diff(state.birthdate, 'months', false)
    },
    dateOfBirth(state) {
      return state.birthdate ? dayjs(state.birthdate).format('DD/MM/YYYY') : '-'
    },
    finishedContracts(state) {
      return state.contracts.filter((u) => FINISHED.includes(u.status.value))
    },
    ongoingPurchaseContractRequests(state) {
      return state.contractRequests.filter((c) => ONGOING_PURCHASE.includes(c.status.value))
    },
    processedContracts(state) {
      return state.contracts.filter((c) => c.status.value === PROCESSED)
    },
    archivedContractRequests(state) {
      return state.contractRequests.filter((c) => c.status.value === ARCHIVED)
    },
    waitingForSignatureContracts(state) {
      return state.contracts
        .filter((c) => c.status.value === WAITING_FOR_SIGNATURE)
        .concat(state.contractRequests.filter((c) => c.status.value === WAITING_FOR_SIGNATURE))
    },
    waitingForSignatureAmendments(state) {
      return state.amendments.filter((c) => c.status.value === WAITING_FOR_SIGNATURE)
    },
    grossPremium(state, getters) {
      let total = 0
      getters.waitingForSignatureContracts.concat(getters.processedContracts).forEach((contract, grossPremium) => {
        total += contract.grossPremium
      })
      return total.toFixed(0)
    },
    pipedriveLink(state) {
      return `${VUE_APP_PIPEDRIVE_URL}/person/${state.externalIds.pipedriveId}`
    },
    sendinblueLink(state) {
      return `${VUE_APP_SENDINBLUE_BASE_URL}/contacts/index/${state.externalIds.sendinblueId}`
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.meta.loading = payload
    },
    [SET_USER](state, payload) {
      state = Object.assign(state, payload)
    },
    EDIT_EMAIL(state, payload) {
      state.email = payload.email
    },
    EDIT_LOCALE(state, payload) {
      state.locale = payload.language
    },
    EDIT_GENDER(state, payload) {
      state.gender = payload.gender
    },
    EDIT_BIRTHDATE(state, payload) {
      state.birthdate = payload.birthdate
    },
    EDIT_PHONE(state, payload) {
      state.mobilePhone = payload.mobile_phone
      state.fixPhone = payload.landline_phone
    },
    EDIT_ADDRESS(state, payload) {
      if (!state.address) {
        state.address = {}
      }
      state.address.streetName = payload.street_name
      state.address.streetBox = payload.street_box
      state.address.streetNumber = payload.street_number
      state.address.city = payload.city
      state.address.zipCode = payload.zip_code
      state.address.country = payload.country
    },
    EDIT_NAME(state, payload) {
      state.firstName = payload.first_name
      state.lastName = payload.last_name
    },
    commitClientChampion(state, operator) {
      state.advisor = operator
    }
  },
  actions: {
    async editClientChampion({ commit }, data) {
      const response = await api.changeClientChampion(data)
      return response
    },
    async editEmail({ commit }, data) {
      const response = await api.changeClientEmail(data)
      if (response.data) commit('EDIT_EMAIL', data.payload)
      return response
    },
    async editGender({ commit }, data) {
      const response = await api.changeUserGender(data)
      if (response.data) commit('EDIT_GENDER', data.payload)
      return response
    },
    async editBirthdate({ commit }, data) {
      const response = await api.changeUserBirthdate(data)
      if (response.data) commit('EDIT_BIRTHDATE', data.payload)
      return response
    },
    async editPhone({ commit }, data) {
      const response = await api.changeUserPhone(data)
      if (response.data) commit('EDIT_PHONE', data.payload)
      return response
    },
    async editAddress({ commit }, data) {
      const response = await api.changeUserAddress(data)
      if (response.data) commit('EDIT_ADDRESS', data.payload)
      return response
    },
    async editName({ commit }, data) {
      const response = await api.changeUserName(data)
      if (response.data) commit('EDIT_NAME', data.payload)
      return response
    },
    async destroy({ state, commit }, data) {
      const { success, message } = await user.delete(data)
      return { success, message }
    },
    async fetch({ state, commit, dispatch }, param) {
      commit(SET_LOADING, true)
      const { success, data, message } = await user.show(param)
      if (success) {
        commit(SET_USER, data)
        commit(SET_LOADING, false)
        // NPS can only be fetched after user email has been set.
        dispatch('journey/fetchNps')
      }
      return { success, data, message }
    },
    async remind({ state, commit }, data) {
      return user.post(data)
    },
    async save({ state, commit }, data) {
      return user.update(data)
    },
    async search({ state, commit }, payload) {
      const response = await user.search(payload)
      return response
    },
    async send({ state, commit }, data) {
      const { success, message } = await user.post(data)
      return { success, message }
    },
    async editLocale({ commit }, data) {
      const response = await api.changeUserLocale(data)
      if (response.data) commit('EDIT_LOCALE', data.payload)
      return response
    }
  }
}
