import initialState from '../_helpers/initialState'
import api from '@/services/api_v2'

export default {
  namespaced: true,
  state: {
    ...initialState,
    claims: null
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_COMPANY_CLAIMS(state, payload) {
      state.claims = payload
    }
  },
  actions: {
    async fetchCompanyClaims({ commit }, vatNumber) {
      commit('SET_LOADING', true)
      const response = await api.getCompanyClaims(vatNumber)
      if (response.data) commit('SET_COMPANY_CLAIMS', response.data)
      commit('SET_LOADING', false)
    }
  }
}
